
import { defineComponent } from "vue";

export default defineComponent({
  name: "AdminQuickResults",
  data() {
    return {
      isLoaded: false,
    };
  },
  async mounted() {
    const isLogged = this.$store.getters.isLogged;
    if (isLogged) {
      this.loadResults();
    }
  },
  methods: {
    async loadResults() {
      await this.$store.dispatch("getDocsById", {
        commitName: "getAllDesktop",
        query: {
          whatFind: "desktop.roundedValue",
          filterOp: ">",
          value: "0",
        },
      });

      await this.$store.dispatch("getDocsById", {
        commitName: "getAllMobile",
        query: {
          whatFind: "mobile.roundedValue",
          filterOp: ">",
          value: "0",
        },
      });

      await this.$store.dispatch("getDocsById", {
        commitName: "getAllMediansDesktop",
        query: {
          whatFind: "desktop.median",
          filterOp: ">",
          value: "0",
        },
      });

      await this.$store.dispatch("getDocsById", {
        commitName: "getAllMediansMobile",
        query: {
          whatFind: "mobile.median",
          filterOp: ">",
          value: "0",
        },
      });
    },
  },
  computed: {
    allDesktop(): { value: number; totalCount: number } {
      return this.$store.getters.getAllDesktop;
    },
    getAllUncorrected(): { value: number; totalCount: number } {
      return this.$store.getters.getAllUncorrected;
    },
    totalCount(): number {
      return this.$store.getters.numberOfDocs;
    },
    allMobile(): { value: number; totalCount: number } {
      return this.$store.getters.getAllMobile;
    },
    getAllMediansDesktop(): { value: number; totalCount: number } {
      return this.$store.getters.getAllMediansDesktop;
    },
    getAllMediansMobile(): { value: number; totalCount: number } {
      return this.$store.getters.getAllMediansMobile;
    },
    getAllMedians(): { value: number; totalCount: number } {
      return this.$store.getters.getAllMedians;
    },
  },
});
