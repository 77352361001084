
import { defineComponent } from "vue";
import firebase from "firebase/app";
import "firebase/auth";
import router from "../../router";

let formData = {
  email: "",
  password: "",
};

export default defineComponent({
  name: "LoginComp",
  props: {
    msg: String,
  },
  data() {
    return {
      formData,
    };
  },
  methods: {
    signIn() {
      firebase
        .auth()
        .signInWithEmailAndPassword(formData.email, formData.password)
        .then(() => {
          console.log("Přihlášení úspěšné");
          this.$store.commit("isLogged", true);
          router.replace("/admin");
        })
        .catch((error) => {
          console.log("err", error.code);
        });
    },
    logout() {
      firebase
        .auth()
        .signOut()
        .then(() => {
          console.log("Odhlášení úspěšné");
          router.replace("/admin");
        })
        .catch((error) => {
          console.log("err", error.code);
        });
    },
  },
});
