
import { defineComponent } from "vue";
import { DocumentData } from "@firebase/firestore-types";

const limit = 10;

export default defineComponent({
  name: "AdminResultsTable",
  data() {
    return {
      firstIndex: 0,
      lastIndex: 0,
      limit,
    };
  },
  async mounted() {
    const isLogged = this.$store.getters.isLogged;
    if (isLogged) {
      this.lastIndex = this.$store.getters.lastDbIndex;
      this.loadResults();
    }
  },
  computed: {
    finalDocs(): DocumentData {
      return this.$store.getters.dbDocPaginated;
    },
  },
  methods: {
    async loadResults() {
      this.$store.commit("setDocsPagination", {
        firstIndex: this.firstIndex,
        limit,
      });
      await this.$store.dispatch("getDocsById", {
        commitName: "getDocsByIdPaginated",
      });
    },
    triggerCurrentChange(value: number) {
      this.firstIndex = (value - 1) * limit;
      this.loadResults();
    },
    indexMethod(index: number) {
      return index + this.firstIndex + 1;
    },
    tableRowClassName({ row }: any) {
      if (row?.desktop?.roundedValue) {
        return "desktop";
      }
      return "";
    },
  },
});
